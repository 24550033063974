#root {
    height: 100vh;
    background-color: rgb(204, 204, 204);
}

.gb {
    background: linear-gradient(0deg, #D5E2F6 0%, #E1EAF9 20.97%, #F7F9FD 69.11%, #FFFFFF 100%);
}

.header {
    height: 90px;
}

.icon-fa {
    position: relative;
    top: -10px;
    left: -8px;
    z-index: 100;
}

.body {
    height: 71vh;
    margin-top: 10px;
    padding: 10px 0px;
    overflow-y: auto;
}

.card-student:last-child {
    margin-bottom: 0px !important;
}

/* width */
::-webkit-scrollbar {
    width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(0, 0, 0, 1);
    border-radius: 3px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 30px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #1d1d1d;
}

.footer {
    height: 50px;
    background: #105596;
    -webkit-border-top-left-radius: 50px;
    -webkit-border-top-right-radius: 50px;
    -moz-border-radius-topleft: 50px;
    -moz-border-radius-topright: 50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    padding: 40px;
    color: white;
    position: fixed;
    bottom: 0px;
    width: 696px;
}

.logo-image {
    background-image: url('../../public/img/app-header.png');
    background-repeat: no-repeat;
    background-size: 720px 100px;
    background-position: center;
    height: 100px;
    width: 100%;
}

.logo {
    width: 100%;
    height: 100px;
}

.row {
    --bs-gutter-x: 0.5rem !important;
}

.input-general {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    background-color: #DDE7F8;
    color: #7093B6;
    appearance: none;
    border: 1px solid #DDE7F8;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.input-general:focus {
    border: 1px solid #DDE7F8;
    color: #7093B6;
}

.btn.btn-primary {
    background-color: #2E8DE5;
    border: #2E8DE5;
    font-weight: bold;
}

.btn.btn-light-info {
    background-color: #DDE7F8;
    color: #7093B6;
    border: 1px solid #7093B600;
}

.area-credentials {
    background-color: #f5f3f39e;
    height: 45vh;
    border-bottom: 2px solid #A5A5A5;
}

.btn.btn-light-info:hover {
    background-color: #bdd3f8;
    color: #7093B6;
    border: 1px solid #7093B600;
}

.bs-button {
    box-shadow: 0px 3px 3px 1px #00000033;
    position: relative;
    z-index: 100;
}

.react-datepicker-wrapper {
    width: 100%;
}

.list-group-item.item-photo {
    background-color: #D9D9D9;
    color: #0374DC;
}

/* HTML: <div class="loader"></div> */
.loading {
    background-color: #00000091;
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    top: 0;
}

.list-items {
    height: 15vh;
    max-height: 17vh;
    overflow-y: auto;
    border-radius: 10px;
    border: solid 1px #b9b9b92e;
}

.watermark-ine-front {
    height: 270px;
    /* max-height: 270px; */
    width: 100%;
    right: 0;
    position: absolute;
    background : url('/public/img/outline-fte.png') no-repeat;
    background-position: center;
    text-align: center;
    color: white;
    font-weight: bold;
    /* background-color: #223b2b; */
}

.watermark-ine-back {
    height: 270px;
    /* max-height: 270px; */
    width: 100%;
    right: 0;
    position: absolute;
    background : url('/public/img/outline-vta.png') no-repeat;
    background-position: center;
    text-align: center;
    color: white;
    font-weight: bold;
    /* background-color: #223b2b; */
}

.w-80 {
    width: 80% !important;
}

.bottom-10 {
    bottom: 10% !important;
}

@media(max-width:768px) {
    .demo-image-preview>img {
        width: 100%;
    }
}

@keyframes l23 {
    100% {
        transform: rotate(1turn)
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 720px;
    }
}

@media (max-width: 448px) {
    .fa-circle.fa-xs {
        font-size: 0.5em;
    }
}